import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Box } from '@mui/material';
import ReactGA from 'react-ga4';
import './css/videoPlayer.css';

const VideoPlayer = () => {
  const videoData = useSelector((state) => state.video);
  const playerRef = useRef(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [milestones, setMilestones] = useState({
    twentyFive: false,
    fifty: false,
    seventyFive: false,
    Hundred: false,
  });

  const videoUrl = videoData?.currentPlayingVideo?.url || '';
  const urlParts = videoUrl.split('/');
  const videoTitle = (videoData?.currentPlayingVideo?.caption || '').replace(/\s+/g, '_');
  const videoId = urlParts[urlParts.length - 1].split('.')[0];
  const pageLocation = window.location.href;

  const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;

  // Initialize Google Analytics only once
  useEffect(() => {
    ReactGA.initialize(GA_TRACKER_ID);
  }, [GA_TRACKER_ID]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, 'seconds');
    }

    // Reset milestones when a new video is loaded
    setMilestones({
      twentyFive: false,
      fifty: false,
      seventyFive: false,
      Hundred: false,
    });
  }, [videoData.currentPlayingVideo]);

  const dispatchVideoEventGA4 = (action, videoUrl, videoTitle, customDimensions = {}) => {
    ReactGA.event({
      category: 'Video',
      action,
      label: videoUrl,
      video_title: videoTitle,
      ...customDimensions,
    });
  };

  const handlePlay = () => {
    dispatchVideoEventGA4('Play', videoUrl, videoTitle, {
      video_id: videoId,
      page_location: pageLocation,
    });
  };

  const handleProgress = (progressPercent) => {
    dispatchVideoEventGA4('Progress', videoUrl, videoTitle, {
      video_id: videoId,
      progress_percent: progressPercent?.toFixed(2),
      page_location: pageLocation,
    });
  };

  const handleEnded = () => {
    dispatchVideoEventGA4('Complete', videoUrl, videoTitle, {
      video_id: videoId,
      page_location: pageLocation,
    });
  };

  useEffect(() => {
    if (playerRef.current) {
      const duration = playerRef.current.getDuration();
      if (duration) {
        const progressPercent = (playedSeconds / duration) * 100;

        if (progressPercent >= 25 && !milestones.twentyFive) {
          handleProgress(25);
          setMilestones((prev) => ({ ...prev, twentyFive: true }));
        } else if (progressPercent >= 50 && !milestones.fifty) {
          handleProgress(50);
          setMilestones((prev) => ({ ...prev, fifty: true }));
        } else if (progressPercent >= 75 && !milestones.seventyFive) {
          handleProgress(75);
          setMilestones((prev) => ({ ...prev, seventyFive: true }));
        } else if (progressPercent >= 95 && !milestones.Hundred) {
          handleProgress(100);
          setMilestones((prev) => ({ ...prev, Hundred: true }));
        }
      }
    }
  }, [playedSeconds, milestones]);

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', pb: '56.25%', height: 0 }}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        playing
        controls
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        light="https://media.istockphoto.com/id/502037149/photo/cricket-action.jpg?s=612x612&w=0&k=20&c=zKWIx8c-5BGepNLpmWsQOoaNkZCEAdLr3KT_r8Y8maI="
        onStart={handlePlay}
        onProgress={(state) => setPlayedSeconds(state.playedSeconds)}
        onEnded={handleEnded}
      />
    </Box>
  );
};

export default VideoPlayer;
