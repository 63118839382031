import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './css/calenderview.css';
import { setSelectedDate, clearGetAllMatches } from '../redux/match/match.slice';

const CalendarView = ({ setCurrentPage, fetchMatchesAction, tournamentId }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedCardIndex, setSelectedCardIndex] = useState(3);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const dispatch = useDispatch();
  const calendarPickerRef = useRef(null);

  const formatDate = (date, forDisplay = false) => {
    return forDisplay ? moment(date).format('MMM DD').toUpperCase() : moment(date).format('DD-MM-YYYY');
  };

  const isToday = (date) => {
    return moment(date).isSame(new Date(), 'day');
  };

  const isYesterday = (date) => {
    return moment(date).isSame(moment().subtract(1, 'day'), 'day');
  };

  const isTomorrow = (date) => {
    return moment(date).isSame(moment().add(1, 'day'), 'day');
  };

  const updateCalendarData = (newDate) => {
    const date = newDate || currentDate;
    const dayBeforeYesterday = moment(date).subtract(2, 'days');
    const yesterday = moment(date).subtract(1, 'days');
    const tomorrow = moment(date).add(1, 'days');
    const dayAfterTomorrow = moment(date).add(2, 'days');

    const newCalendarData = [
      {
        day: dayBeforeYesterday.format('ddd').toUpperCase(),
        date: formatDate(dayBeforeYesterday, true),
        dispatchDate: formatDate(dayBeforeYesterday),
      },
      {
        day: yesterday.format('ddd').toUpperCase(),
        date: isYesterday(yesterday) ? 'Yesterday' : formatDate(yesterday, true),
        dispatchDate: formatDate(yesterday),
      },
      {
        day: moment(date).format('ddd').toUpperCase(),
        date: isToday(date) ? 'Today' : formatDate(date, true),
        dispatchDate: formatDate(date),
      },
      {
        day: tomorrow.format('ddd').toUpperCase(),
        date: isTomorrow(tomorrow) ? 'Tomorrow' : formatDate(tomorrow, true),
        dispatchDate: formatDate(tomorrow),
      },
      {
        day: dayAfterTomorrow.format('ddd').toUpperCase(),
        date: formatDate(dayAfterTomorrow, true),
        dispatchDate: formatDate(dayAfterTomorrow),
      },
    ];
    setCalendarData(newCalendarData);
  };

  const handleDateChange = (date) => {
    dispatch(clearGetAllMatches());
    setCurrentPage(1);
    setCurrentDate(date);
    setCalendarOpen(false);
    setDateSelected(!isToday(date));

    sessionStorage.removeItem('selectedCardIndex');
    sessionStorage.setItem('selectedDate', formatDate(date));

    const index = calendarData.findIndex((item) => item.dispatchDate === formatDate(date));
    if (index !== -1) {
      setSelectedCardIndex(index + 1);
    } else {
      setSelectedCardIndex(0);
    }

    dispatch(setSelectedDate(formatDate(date)));

    if (fetchMatchesAction && tournamentId) {
      dispatch(fetchMatchesAction(tournamentId));
    }
    updateCalendarData(date);
  };

  useEffect(() => {
    const savedDate = sessionStorage.getItem('selectedDate');
    if (savedDate) {
      const savedMoment = moment(savedDate, 'DD-MM-YYYY').toDate();
      setCurrentDate(savedMoment);
      setDateSelected(true);
      updateCalendarData(savedMoment);
    } else {
      updateCalendarData();
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('selectedDate');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarPickerRef.current && !calendarPickerRef.current.contains(event.target)) {
        setCalendarOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const savedIndex = sessionStorage.getItem('selectedCardIndex');
    const savedDate = sessionStorage.getItem('selectedDate');
    if (savedDate) {
      const savedDateMoment = moment(savedDate, 'DD-MM-YYYY');
      const index = calendarData.findIndex((item) => item.dispatchDate === formatDate(savedDateMoment));
      if (index !== -1) {
        setSelectedCardIndex(index + 1);
      }
    } else {
      setSelectedCardIndex(0);
    }
  }, [calendarData]);

  const handleCalendarCardClick = (date, index) => {
    setCurrentPage(1);
    dispatch(clearGetAllMatches());
    dispatch(setSelectedDate(date));
    setSelectedCardIndex(index);
    sessionStorage.setItem('selectedCardIndex', index);
    sessionStorage.setItem('selectedDate', date);
  };

  const handleAllCardClick = () => {
    setCurrentPage(1);
    dispatch(clearGetAllMatches());
    dispatch(setSelectedDate(null));
    setSelectedCardIndex(0);
    sessionStorage.setItem('selectedCardIndex', 0);
    sessionStorage.removeItem('selectedDate');

    if (fetchMatchesAction && tournamentId) {
      dispatch(fetchMatchesAction(tournamentId));
    }
  };

  return (
    <div className="calendar-page">
      <div className="all-button-container">
        <div
          className="calendar-item all-button"
          style={{
            backgroundColor: selectedCardIndex === 0 ? '#495eca' : '#7BCFED',
          }}
          onClick={handleAllCardClick}
        >
          <p>ALL</p>
        </div>
      </div>
      <div className="calendar-view-container">
        <div className="calendar-scrollable">
          {calendarData.map((item, index) => (
            <div
              key={index}
              className="calendar-item"
              style={{
                backgroundColor: selectedCardIndex === index + 1 ? '#FDE380' : 'transparent',
              }}
              onClick={() => {
                setSelectedCardIndex(index + 1);
                handleCalendarCardClick(item.dispatchDate, index + 1);
              }}
            >
              <div className="day-text">
                <p>{item.day}</p>
              </div>
              <div className="date-text">
                <p>{item.date}</p>
              </div>
            </div>
          ))}
        </div>
        <CalendarMonthIcon
          sx={{
            fontSize: { xs: '30px', sm: '30px', md: '40px' },
            marginLeft: { xs: '0px', sm: '0px', md: '10px' },
            marginRight: { xs: '0px', sm: '0px' },
            marginTop: { xs: '-2px', sm: '0px' },
            cursor: 'pointer',
          }}
          onClick={(event) => {
            event.stopPropagation();
            setCalendarOpen(true);
          }}
        />
      </div>
      {calendarOpen && (
        <div ref={calendarPickerRef} className="calendar-picker-overlay">
          <DatePicker
            selected={currentDate}
            onChange={(date) => handleDateChange(date)}
            onClose={() => setCalendarOpen(false)}
            inline
          />
        </div>
      )}
    </div>
  );
};

export default CalendarView;
