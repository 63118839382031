import React, { useState, useRef, useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import Navbar from '../Navbar';
import MainWidthWrapper from '../MainWidthWrapper';
import backIcon from '../../assets/chevron-left.svg';
import TeamLogo from '../ui/TeamLogo';
import PlayersDetails from './PlayersDetails';
import { useDispatch, useSelector } from 'react-redux';
import AuthWall from '../auth/AuthWall';
import api from '../../api/api';
import { successnotify, Errornotify } from '../ToastMessage';

const CreationForm = () => {
  const { isAuthenticated, showAuthWall } = useSelector((state) => state.auth);
  const [isActive, setIsActive] = useState(false);
  const [focusedField, setFocusedField] = useState('');
  const [toggled, setToggled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [inputs, setInputs] = useState({
    teamName: '',
    teamHandle: '',
    playersData: Array.from({ length: 3 }, () => ({ playerName: '', playerContact: '' })),
  });
  const [charCount, setCharCount] = useState({
    teamNameCount: 0,
    teamHandleCount: 0,
  });
  const handleToggle = () => {
    setToggled((prev) => !prev);
  };
  const handleFocus = (e) => {
    const { name } = e.target;
    setFocusedField(name); // Set the currently focused input field's name
  };
  const handleBlur = () => {
    setFocusedField(''); // Clear the focus when the input loses focus
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInput) => ({ ...prevInput, [name]: value }));
    setCharCount((prevCount) => ({ ...prevCount, [`${name}Count`]: value.length }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredPlayers = inputs.playersData.filter(
      (player) => player.playerName.trim() !== '' && player.playerContact.trim() !== ''
    );
    const isPhoneNumber = (input) => {
      // Simple regex for phone numbers (you can improve it based on your needs)
      const phoneRegex = /^\+91\s?[6-9]\d{9}$/; // Adjust for different countries
      return phoneRegex.test(input);
    };
    const isEmail = (input) => {
      // Simple email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(input);
    };
    if (isAuthenticated && !toggled && filteredPlayers.length > 0) {
      setLoader(true);
      const requestPayload = {
        teamHandle: inputs.teamHandle,
        teamName: inputs.teamName,
        players: filteredPlayers.map((player) => {
          const ContactOrEmail = player.playerContact;
          return {
            name: player.playerName,
            contactNum: isPhoneNumber(ContactOrEmail) ? ContactOrEmail.slice(4) : '',
            countryCode: '+91', // Adjust if needed
            email: isEmail(ContactOrEmail) ? ContactOrEmail : '',
          };
        }),
        platform: 'web',
        bulkAddPlayers: true,
      };
      try {
        const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v1/cricket/team/create/`, requestPayload);
        successnotify('Team created successfully');
        setInputs((prevState) => ({
          ...prevState,
          teamName: '',
          teamHandle: '',
          playersData: prevState.playersData.map((player) => ({
            playerName: '',
            playerContact: '',
          })),
        }));
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoader(false);
      }
    } else if (isAuthenticated && toggled && filteredPlayers.length > 0) {
      setLoader(true);
      const requestPayload = {
        players: filteredPlayers.map((player) => {
          const ContactOrEmail = player.playerContact;
          return {
            name: player.playerName,
            contactNum: isPhoneNumber(ContactOrEmail) ? ContactOrEmail.slice(4) : '',
            countryCode: '+91', // Adjust if needed
            email: isEmail(ContactOrEmail) ? ContactOrEmail : '',
          };
        }),
      };
      try {
        const response = await api.post(`${process.env.REACT_APP_API_BASE_URL}api/v0/cricket/players/bulkAdd/`, requestPayload);
        successnotify('Players added successfully');
        setInputs((prevState) => ({
          ...prevState,
          playersData: prevState.playersData.map((player) => ({
            playerName: '',
            playerContact: '',
          })),
        }));
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoader(false);
      }
    } else if (isAuthenticated && !toggled) {
      Errornotify('Please Enter Team Details');
    } else if (isAuthenticated && toggled) {
      Errornotify('Please Enter Player Details');
    } else {
      Errornotify('Please login to continue');
    }
  };
  useEffect(() => {
    const isFieldFilled = !toggled
      ? inputs.teamName.trim() !== '' && inputs.teamHandle.trim() !== ''
      : inputs.playersData.some((player) => player.playerName.trim() !== '' || player.playerContact.trim() !== '');
    if (isFieldFilled) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [inputs.teamName, inputs.teamHandle, inputs.playersData]);
  return (
    <>
      <MainWidthWrapper>
        {!isAuthenticated && <AuthWall isVisible={showAuthWall} />}
        <div className="px-5 py-4 flex flex-col gap-10">
          {/* -------------------NavBar----------------------------------------- */}
          <div className="flex flex-col md:flex-row items-center justify-between gap-2">
            <div className="flex justify-self-start gap-2">
              <img src={backIcon} alt="Gully Ball" className="w-6 h-6" />
              <p className="text-[17px] font-roboto font-semibold">Quick Team and Player Registration</p>
            </div>
            <div className="w-[180px] h-11 bg-[#DBB42C] rounded-l-full rounded-r-full flex gap-1 p-2 md:justify-self-end">
              <div
                className={`w-[50%] ${
                  toggled ? 'bg-[#DBB42C]' : 'bg-[#FFFFFF]'
                }  cursor-pointer px-1 py-2 rounded-l-full rounded-r-full flex justify-center items-center`}
                onClick={handleToggle}
              >
                <p className={`${toggled ? 'text-[#FFFFFF]' : 'text-[#000000]'}  font-bold text-[10px] text-justify-center`}>
                  {' '}
                  Register Team{' '}
                </p>
              </div>
              <div
                className={`w-[50%] ${
                  toggled ? 'bg-[#FFFFFF]' : 'bg-[#DBB42C]'
                }  cursor-pointer px-0 py-2 rounded-l-full rounded-r-full flex justify-center items-center`}
                onClick={handleToggle}
              >
                <p className={`${toggled ? 'text-[#000000]' : 'text-[#FFFFFF]'} font-bold text-[10px] text-justify-center`}>
                  {' '}
                  Players Only
                </p>
              </div>
            </div>
          </div>
          {/*-------------------------- Enter Team Name---------------------------------- */}
          <div className={`${toggled ? 'hidden' : 'block'} w-full h-[45px] flex gap-2`}>
            <TeamLogo />
            <div className="w-full h-[59px] flex flex-col">
              <p className="font-roboto text-[12px] font-semibold leading-[20px] text-left">TEAM NAME</p>
              <input
                type="text"
                name="teamName"
                placeholder="Enter your team name"
                className="w-full h-[40px] p-[10px_12px] rounded-lg border border-gray-300 bg-transparent border-gradient  focus:outline-none"
                onChange={handleInputChange}
                maxLength="30"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              {focusedField === 'teamName' && (
                <div className="flex justify-between">
                  <p className="font-roboto my-1 text-[10px] font-medium leading-[10px] tracking-[1px] text-left bg-clip-text ">
                    Only alphabets and numbers
                  </p>
                  <p className="font-roboto my-1 text-[10px] font-medium leading-[10px] tracking-[1px] text-left bg-clip-text ">
                    {`${charCount.teamNameCount}/30`}
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* ----------------------------Team Handle------------------------------------------- */}
          <div className={` ${toggled ? 'hidden' : 'block'} w-full h-[50px] flex flex-col gap-[7px]`}>
            <p className="font-roboto text-[12px] font-semibold leading-[20px] text-left">TEAM HANDLE</p>
            <div className="relative w-full">
              {/* Bold "@" symbol */}
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-black font-bold">@</span>
              {/* Input field */}
              <input
                type="text"
                name="teamHandle"
                placeholder="Enter unique team handle"
                className="w-[100%] h-full border border-gray-300 rounded-lg py-2 pl-8 pr-4 bg-transparent border-gradient focus:outline-none"
                style={{
                  borderImageSource:
                    'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1))',
                }}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                maxLength="15"
              />
            </div>
            {focusedField === 'teamHandle' && (
              <div className="flex justify-between">
                <p className="font-roboto my-1 text-[10px] font-medium leading-[10px] tracking-[1px] text-left bg-clip-text ">
                  Only alphabets and numbers
                </p>
                <p className="font-roboto my-1 text-[10px] font-medium leading-[10px] tracking-[1px] text-left bg-clip-text ">
                  {`${charCount.teamHandleCount}/15`}
                </p>
              </div>
            )}
          </div>
          {/*-------------------------------- Invite teams-------------------------------------- */}
          <PlayersDetails inputs={inputs} setInputs={setInputs} />
          {/* ------------------------------- Create Team Button----------------------------------------- */}
          <div className="flex justify-center items-center w-full h-full" onClick={handleSubmit}>
            <div
              className={`w-4/5 h-[40px]  ${
                !isActive ? 'bg-[#0A0A0A1A]' : 'bg-[#FFCB05]'
              } rounded-lg flex items-center justify-center gap-2 cursor-pointer  `}
            >
              {loader ? (
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-7 h-7 text-gray-200 animate-spin dark:text-white fill-yellow-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <div className="flex flex-row gap-2 justify-center align-center">
                  <p
                    className={` ${
                      !isActive ? 'text-white' : 'text-black'
                    } font-roboto text-[14px] font-medium leading-[20px] tracking-[0.005em] text-left mt-1`}
                  >
                    {!toggled ? 'Create Team' : 'Register Player'}
                  </p>
                  <CheckIcon className={`w-[18px] h-[18px] ${isActive ? 'text-black' : 'text-white'}`} />
                </div>
              )}
            </div>
          </div>
        </div>
      </MainWidthWrapper>
    </>
  );
};
export default CreationForm;
