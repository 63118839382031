import React from 'react';
import { Box } from '@mui/system';
import GullyBallLogo from './ui/GullyBallLogo copy';
import Avatar from '../assets/Avatar Fill.svg';
import Award from '../assets/interface-award-trophy--reward-rating-trophy-social-award-media.svg';
import Team from '../assets/team.svg';
import BatIcon from '../assets/v6-icon.svg';

const MainWidthWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        fontFamily: 'Arial, sans-serif',
        mx: 'auto',
        width: { md: '55%', mg: '100%' },
      }}
    >
      {children}
    </Box>
  );
};

const Navbar = ({ onBatClick, onAvatarClick, onAwardClick, onTeamClick, onlogoClick }) => (
  <MainWidthWrapper>
    <div className="h-[40px] flex justify-between px-5 py-5 bg-white">
      <div className="w-[190px] flex items-center" onClick={onlogoClick}>
        <GullyBallLogo />
        <p className="text-[16px] leading-[26px] font-poppins font-extrabold tracking-[0.02em] ml-[6px]">GULLY BALL</p>
      </div>

      <div className="flex gap-[19px] items-center">
        <img src={BatIcon} alt="Placeholder Image" className="w-[24px] h-[24px] cursor-pointer" onClick={onBatClick} />
        <img src={Award} alt="Placeholder Image" className="w-[24px] h-[24px] cursor-pointer" onClick={onAwardClick} />
        <img src={Team} alt="Placeholder Image" className="w-[27px] h-[20px] cursor-pointer" onClick={onTeamClick} />
        <img src={Avatar} alt="Avatar" className="w-[22px] h-[22px] cursor-pointer" onClick={onAvatarClick} />
      </div>
    </div>
    <div className="w-full h-[2px] bg-[#F2F4F5]"></div>
  </MainWidthWrapper>
);

export default Navbar;
