import React from 'react';
import { Box } from '@mui/material';
import Frame from '../../assets/Frame 1000004700 (1).svg';
const GullyBallLogo = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20px',
          height: '20px',
          borderRadius: '50%', // Makes the container a circle
          backgroundColor: '#AE2137', // Custom color for the circle
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Ensure SVGs are spaced side by side
            width: '100px', // Adjust width to fit 3 SVGs
          }}
        >
          <img src={Frame} alt="Gully Ball" width="20" height="20" />
        </Box>
      </Box>
    </>
  );
};
export default GullyBallLogo;
