import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useHistory, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { fetchMatchData } from '../../redux/match/match.action';
import Result from '../../components/Result';
import ScoreCard from '../../components/scoreCard';
import VideoList from '../../components/videoList';
import VideoPlayer from '../../components/videoPlayer';
import MainHeading from '../../components/Headings/MainHeading';
import MainWidthWrapper from '../../components/MainWidthWrapper';
import Analysis from '../../components/Analysis';
import Awards from '../../components/Awards';
import socketIOClient from 'socket.io-client';
import {
  setCurrScoreOnRealTimeUpdate,
  setIsLive,
  setViews,
  setcurrPartnerships,
  setcurroverdetails,
} from '../../redux/match/match.slice';
// import LiveWinProbabilityPredictor from '../../components/WinProbability';
import Table from '../../components/MatchInfo';
import CustomTabs from '../../components/Tabs';
import OverTracker from '../../components/Overtracker';
import LiveScoringButton from '../../components/LiveButton';
import moment from 'moment';
import { getUserIdentifier } from '../../helpers/userUuid';
import BackIcon from '../../components/icons/BackIcon';
import AuthWall from '../../components/auth/AuthWall';
import ReactGA from 'react-ga4';

const MatchContainer = () => {
  const dispatch = useDispatch();
  const { matchId } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);

  const matchData = useSelector((state) => state.match);
  const videoData = useSelector((state) => state.video);
  const { isAuthenticated, showAuthWall } = useSelector((state) => state.auth);

  const fullMatchVideo = videoData?.summaryAndFullMatchVideosList?.find((video) => video.videoCategory === 'FULL_MATCH_VIDEO');

  const guestUserClientId = getUserIdentifier();
  const disconnectSocket = (isLive, socket) => {
    if (!isLive) {
      socket.disconnect();
    }
    return;
  };
  useEffect(() => {
    dispatch(fetchMatchData(matchId, true, true, true));
  }, [matchId, dispatch]);
  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_API_BASE_URL, {
      path: '/api/ws/',
      withCredentials: true,
      auth: {
        userId: guestUserClientId,
      },
    });
    let disconnectSocketTimer;
    let fetchMatchDataTimer;
    let joinMatchTimer;
    const handleBeforeUnload = () => {
      clearTimeout(fetchMatchDataTimer);
    };
    if (!matchData?.isLive) {
      disconnectSocketTimer = setTimeout(() => {
        disconnectSocket(matchData?.isLive, socket);
      }, 60000);
    }
    socket.on('connect', () => {
      joinMatchTimer = setTimeout(() => {
        socket.emit('joinMatch', { matchId, guestUserClientId });
      }, 2000);
    });

    // Listen for updates specific to the matchId
    socket.on(`match:${matchId}`, (updatedMatch) => {
      if (!updatedMatch?.isLive) {
        fetchMatchDataTimer = setTimeout(() => {
          dispatch(fetchMatchData(matchId, true, true, true));
        }, 3000);
      } else {
        dispatch(setIsLive(true));
      }
      dispatch(setCurrScoreOnRealTimeUpdate(updatedMatch));
    });
    // Listen for view count updates
    socket.on('viewCountUpdate', (viewCountData) => {
      dispatch(setViews(viewCountData));
    });
    // Listen for partnership updates
    socket.on(`match:psh:${matchId}`, (data) => {
      dispatch(setcurrPartnerships(data));
    });
    // Listen for over by over details
    socket.on(`match:ovbbb:${matchId}`, (data) => {
      dispatch(setcurroverdetails(data));
    });

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (fetchMatchDataTimer) {
        clearTimeout(fetchMatchDataTimer);
      }
      if (disconnectSocketTimer) {
        clearTimeout(disconnectSocketTimer);
      }
      if (socket) {
        clearTimeout(joinMatchTimer);
        socket.disconnect();
      }
    };
  }, [matchId, matchData?.isLive, dispatch]);

  const { info } = matchData.data?.[0] || {};
  const { date, numOfPlayerInEachTeam, limitedOver, tossWinner, tossDecision } = info?.[0] || {};

  const matchDate = moment(date).utc().format('dddd, MMMM DD, YYYY');
  const matchTime = moment(date).utc().format('hh:mm A');
  const format = `Gully ${numOfPlayerInEachTeam}X${limitedOver}`;

  let tossDecisionText = '-';
  if (tossDecision === 'Batting') {
    tossDecisionText = 'elected to bat first';
  } else if (tossDecision === 'Bowling') {
    tossDecisionText = 'elected to field first';
  }
  const truncatedTossWinner = tossWinner ? (tossWinner.length > 12 ? `${tossWinner.slice(0, 12)}...` : tossWinner) : '-';
  const Toss = truncatedTossWinner !== '-' ? `${truncatedTossWinner.toUpperCase()}, ${tossDecisionText}` : null;
  const Details = matchData?.tournamentDetails || null;
  const tournamentname = matchData?.tournamentDetails?.name || '--';
  const userProfile = matchData?.userProfile || {};
  const Name = userProfile?.name?.firstName || '--';
  const username = userProfile?.userName || '--';
  const ProfilePic = userProfile?.profilePic || '';
  const tournamentId = matchData?.tournamentId || '';
  const isdummymatch = matchData?.isDummy;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const capitalizedName = capitalizeFirstLetter(Name);
  const columns = [
    ...(Details
      ? [
          {
            label: 'Tournament Name',
            imgUrl: '',
            alignment: 'right',
            value: (
              <Link to={`/tournament-profile/${tournamentId}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                {tournamentname.length > 25 ? `${tournamentname.substring(0, 25)}...` : tournamentname}
              </Link>
            ),
          },
        ]
      : []),

    {
      label: 'Match Format',
      imgUrl: '',
      alignment: 'right',
      value: format,
    },
    {
      label: 'Date',
      imgUrl: '',
      alignment: 'right',
      value: matchDate,
    },
    ...(Toss
      ? [
          {
            label: 'Toss',
            imgUrl: '',
            alignment: 'right',
            value: Toss,
          },
        ]
      : []),
    {
      label: 'Match Creator',
      alignment: 'right',
      value: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={ProfilePic}
            alt="Profile"
            style={{
              borderRadius: '50%',
              height: '2.5em',
              width: '2.5em',
              marginRight: '0.5em',
            }}
          />
          <Link to={`/player/${username}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {capitalizedName}
          </Link>
        </div>
      ),
    },
  ];

  const GA_TRACKER_ID = process.env.REACT_APP_GA_TRACKER_ID;

  const tabs = [
    { value: 0, label: 'VIDEOS', path: 'videos', isVisible: false },
    { value: 1, label: 'SCORECARD', path: 'scorecard', isVisible: true },
    { value: 2, label: 'ANALYSIS', path: 'analysis', isVisible: true },
    { value: 3, label: 'AWARDS', path: 'awards', isVisible: true },
    { value: 4, label: 'INFO', path: 'info', isVisible: true },
  ];

  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname.includes(tab.path));
    if (currentTab && currentTab.value !== activeTab) {
      setActiveTab(currentTab.value);
    }
  }, [location.pathname]);

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);

    const selectedTab = tabs.find((tab) => tab.value === newValue);

    if (selectedTab) {
      if (!isdummymatch) {
        // Initialize Google Analytics
        ReactGA.initialize(GA_TRACKER_ID);

        // Log tab view in Google Analytics
        ReactGA.send({
          hitType: 'pageview',
          page: `/match/${selectedTab.path}/${matchId}`,
        });
      }

      window.history.pushState({}, '', `/match/${selectedTab.path}/${matchId}`);
    }
  };

  const hasVideos =
    videoData?.individualHiglightClipsList?.length !== 0 || videoData?.summaryAndFullMatchVideosList?.length !== 0;

  tabs.forEach((tab) => {
    if (tab.label === 'VIDEOS') {
      tab.isVisible = hasVideos;
    }
  });

  // Extract the 'webview' parameter from the URL
  const isWebView = searchParams.get('webview') === 'true';

  return (
    <MainWidthWrapper>
      <Box className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gully Six</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </Box>
      {!isWebView && !isAuthenticated && <AuthWall isVisible={!isAuthenticated} />}
      {matchData.loading && <Box>Loading...</Box>}
      {matchData.data && videoData?.individualHiglightClipsList?.length !== 0 ? (
        <>
          {fullMatchVideo ? (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                  p: '2px',
                  cursor: 'pointer',
                }}
              >
                <BackIcon />
              </Box>
              <VideoPlayer />
            </Box>
          ) : (
            <Box sx={{ py: '18px', bgcolor: 'black' }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '16.41px',
                }}
              >
                MATCH PAGE
              </Typography>
            </Box>
          )}
          <Result matchData={matchData.data} />
          <Box sx={{ mt: '0px' }}>
            <CustomTabs activeTab={activeTab} onTabChange={handleTabChange} tabs={tabs} />
            <Box sx={{ mt: '0' }}>
              {activeTab === 1 && (
                <Box sx={{ mt: '0px' }}>
                  <ScoreCard matchData={matchData.data} isLive={matchData.isLive} currScore={matchData.currScore} />
                </Box>
              )}
              {activeTab === 0 && (
                <Box sx={{ mt: '0px' }}>
                  <VideoList />
                </Box>
              )}
              {activeTab === 2 && (
                <Box sx={{ mt: '0px' }}>
                  <Analysis />
                </Box>
              )}
              {activeTab === 3 && (
                <Box sx={{ mt: '0px' }}>
                  <Awards />
                </Box>
              )}
              {activeTab === 4 && (
                <Box sx={{ mt: '0px' }}>
                  <Table columns={columns} />
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        matchData.data &&
        videoData?.individualHiglightClipsList?.length === 0 && (
          <Box sx={{ pb: 4 }}>
            {fullMatchVideo ? (
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'white',
                    p: '2px',
                    cursor: 'pointer',
                  }}
                >
                  <BackIcon />
                </Box>
                <VideoPlayer />
              </Box>
            ) : (
              <Box sx={{ bgcolor: 'black', p: 0.5 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    mb: 0,
                    mr: 5,
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 0,
                      display: 'flex',
                      alignItems: 'center',
                      color: 'white',
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <BackIcon />
                  </Box>
                  <Box sx={{ color: 'white', display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontWeight: '500',
                        marginLeft: '40px',
                      }}
                    >
                      MATCH PAGE
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <LiveScoringButton />
                </Box>
              </Box>
            )}
            <Result matchData={matchData.data} />
            <Box sx={{ mt: '0px' }}>
              {matchData.isLive && (
                <>
                  <OverTracker />
                  {/* <LiveWinProbabilityPredictor /> */}
                </>
              )}

              <CustomTabs activeTab={activeTab} onTabChange={handleTabChange} tabs={tabs} />
              <Box sx={{ mt: '0' }}>
                {activeTab === 1 && (
                  <Box sx={{ mt: '0px' }}>
                    <ScoreCard matchData={matchData.data} isLive={matchData.isLive} currScore={matchData.currScore} />
                  </Box>
                )}
                {activeTab === 2 && (
                  <Box sx={{ mt: '0px' }}>
                    <Analysis />
                  </Box>
                )}
                {activeTab === 3 && (
                  <Box sx={{ mt: '0px' }}>
                    <Awards />
                  </Box>
                )}
                {activeTab === 4 && (
                  <Box sx={{ mt: '0px' }}>
                    <Table columns={columns} />
                  </Box>
                )}
                {activeTab === 0 && (
                  <Box sx={{ mt: '0px' }}>
                    <VideoList />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )
      )}
    </MainWidthWrapper>
  );
};

export default MatchContainer;
