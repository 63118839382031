import { Box } from '@mui/system';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MainWidthWrapper from './MainWidthWrapper';
import { fetchAllMatches } from '../redux/match/match.action';
import { setSelectedDate, clearGetAllMatches } from './../redux/match/match.slice';
import CalendarView from './CalenderView';
import MatchItem from './MatchItem';
import CenteredLinks from './CenteredLinks';
import moment from 'moment';

const Match = () => {
  const [currentPage, setPage] = useState(1);
  const selectedDate = useSelector((state) => state.match.date);
  const dispatch = useDispatch();
  const getAllMatches = useSelector((state) => state.match.getAllMatches, shallowEqual);
  const { pageSize, totalPages } = useSelector((state) => state.match);
  const [isFetching, setIsFetching] = useState(false);
  const links = [
    { text: 'About Us', url: '/about-us' },
    { text: 'Privacy Policy', url: '/privacy-policy' },
    { text: 'Terms of Service', url: '/terms-of-service' },
  ];

  useEffect(() => {
    setPage(1);
  }, [selectedDate]);

  useEffect(() => {
    if (currentPage <= totalPages && !isFetching) {
      setIsFetching(true);
      if (selectedDate === null) {
        dispatch(fetchAllMatches(currentPage, pageSize)).finally(() => setIsFetching(false));
      } else {
        dispatch(fetchAllMatches(currentPage, pageSize, selectedDate)).finally(() => setIsFetching(false));
      }
    }
  }, [dispatch, currentPage, pageSize, selectedDate]);

  const handleInfinityScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleInfinityScroll);
    return () => window.removeEventListener('scroll', handleInfinityScroll);
  }, [handleInfinityScroll]);

  useEffect(() => {
    return () => {
      dispatch(clearGetAllMatches());
    };
  }, [dispatch]);

  const handleCalendarDateSelect = (date) => {
    setPage(1);
    dispatch(setSelectedDate(date));
  };
  console.log(window.location.origin);

  return (
    <>
      <MainWidthWrapper>
        <Box sx={{ zIndex: '1000', position: 'sticky', top: '40px', backgroundColor: '#fff', px: 2 }}>
          {window.location.origin.includes('gullyball') && (
            <Box>
              <CenteredLinks links={links} />
            </Box>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginBottom: '6px',
              marginLeft: '4px',
            }}
          >
            <span
              style={{
                marginRight: '8px',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '16.41px',
                textAlign: 'left',
                marginTop: '8px',
              }}
            >
              MATCHES
            </span>
            <hr style={{ flex: 1, border: '0.5px solid black', marginTop: '8px' }} />
          </div>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '100%' }}>
              <CalendarView setCurrentPage={setPage} onSelectDate={handleCalendarDateSelect} sx={{ width: '100%' }} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ px: 2, py: 2 }}>
          {getAllMatches &&
            getAllMatches.map((match) => (
              <Box key={match._id}>
                <MatchItem match={match} />
              </Box>
            ))}
        </Box>
      </MainWidthWrapper>
    </>
  );
};
export default Match;
